import styled from 'styled-components'

const ContactDescription = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: ${({ theme }) => theme.colors.body};
`

export default ContactDescription
